import React from 'react';
import { Link } from 'gatsby';
import withContext from 'store/withContext';

import {MailingListForm} from 'components/Forms';

// import Button from 'components/Global/Button';

import PageWrapper from '../styles/vegas.css.js';

const Leagues = (props) => {

  return (
    <PageWrapper>
      <main className="vegas-content">
        <section className="header">
          <img className="logo" src={'https://webcdn.hirezstudios.com/smite-esports/assets/s2v-logo.png'}/>
        </section>
        
        <div className="center-wrapper">
          <section className="about">
            <h1>JANUARY 17-19</h1>
            <h2>HYPERX ARENA LAS VEGAS</h2>
            <h5>The SMITE 2 Founders Series will be kicking off September 14th, leading up to the first live SMITE 2 event in the HyperX Arena Las Vegas on January 17-19, 2025.</h5>
            <h6>Tickets will go on sale September 13th, 2024, so get ready! The exact time will be announced a little closer to the sale date.</h6>
          </section><section className="about">
            
          </section>
        </div>
      </main>
    </PageWrapper>
  );
};

export default withContext(Leagues);
