import styled from 'styled-components';

const PageWrapper = styled.div`
  @media screen and (max-width: 768px) {
    padding-bottom: initial;
  }
  .vegas-content {
    color: rgb(255,255,255);
    background: var(--background);
    background-image: url("https://webcdn.hirezstudios.com/smite-esports/assets/s2v-background.png");
    background-size: cover;
    box-shadow: 0px .3rem .6rem rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    padding-bottom:3rem;
    width: 100%;
    a {
      color:white;
    }
    @media screen and (max-width: 768px) {
      margin-top: initial;
    }
    .center-wrapper {
      margin: 0 auto;100%;
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items:center;
      section {
        /*padding: 3rem 0 3rem 0;*/
      }
    }
    input[type=""], input[type="email"]{

      border: 3px solid #black;
      border-radius: 5px;
      height: 50px;
      line-height: normal;
      color: #282828;
      display: block;
      width: 100%;
      box-sizing: border-box;
      user-select: auto;
      font-size: 16px;
      padding: 0 6px;
      padding-left: 12px;
      :focus{
          border: none;
          outline:none;
          box-shadow: 0 0 0.2rem rgb(255 216 0), 0 0 0.2rem rgb(255 216 0),0 0 0.8rem rgb(255 216 0), 0 0 0.8rem rgb(255 216 0), inset 0 0 0.6rem rgb(255 216 0);
      
      }
      
    }
    form span p {
      margin:0.4rem 0;
    }
    label {
      color: white;
      font-family: var(--font-body);
      font-size: 1.4rem;
      line-height: 1.5;
      margin: 0.2rem 0;
    }
    button {
      margin-top:1rem;
      border: 1rem;
      cursor: pointer;
      outline: none;
      font-size: 16px;
      -webkit-transform: translate(0);
      transform: translate(0);
      background-color: #ffc4c4;
      padding: 0.7em 2em;
      border-radius: 2rem;
      box-shadow: 0 0 0.2rem rgb(245 0 0), 0 0 0.2rem rgb(245 0 0),0 0 0.8rem rgb(245 0 0), 0 0 0.8rem rgb(245 0 0), inset 0 0 1.2rem rgb(245 0 0);
      -webkit-transition: box-shadow 0.25s;
      transition: box-shadow 0.25s;
      color: white;
    }
    
    button .text {
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(45deg, #4568dc, #b06ab3);
    }
    
    button:after {
      content: "";
      border-radius: 18px;
      position: absolute;
      margin: 0.3rem;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      background: #0e0e10;
      box-shadow: inset 0 0 1.2rem rgb(245 0 0);
    }
    
    button:hover {
      background-color: #ff0303;
      box-shadow: 0 0 0.2rem rgb(245 0 0), 0 0 0.2rem rgb(245 0 0),0 0 0.8rem rgb(245 0 0), 0 0 0.8rem rgb(245 0 0), inset 0 0 1.2rem rgb(245 0 0);
    }
    
    button:hover .text {
      background-image: linear-gradient(-45deg, #4568dc, #b06ab3);
    }
    h1 {
      font-size:7.3rem;
      color:white;
      text-transform: uppercase;
      letter-spacing: 3px;
      text-align:center;
      @media screen and (max-width: 768px) {
       font-size:3rem;
      }
    }
    h2 {
      font-size:5.3rem;
      color:white;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin: 1rem 0;
      text-align:center;
      @media screen and (max-width: 768px) {
        font-size:2.6rem;
       }
    }
    h5 {
      color: white;
      font-family: var(--font-body);
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 1.5;
      margin: 1rem 0;
      text-align:center;
    }
    h6 {
      color: white;
      font-family: var(--font-body);
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 1.5;
      margin: 1rem 0;
      text-align:center;
      @media screen and (max-width: 768px) {
        font-size:1.8rem;
       }
    }
    p {
      color: white;
      font-family: var(--font-body);
      font-size: 1.4rem;
      line-height: 1.5;
      margin: 1rem 0;
    }
    .message{
      color:red;
      display: flex;
      justify-content: center;
      margin: 0.6rem 0 0 0;
      max-width:394px;
    }
    .message.success{
      color:#00a8ff;
    }
    .button {
      background: var(--primary);
      color: var(--background);
      display: inline-block;
      font-size: 1.4rem;
      font-weight: bold;
      margin-top: 1.5rem;
      padding: 1rem 1.4rem;
      text-decoration: none;
      text-transform: uppercase;
      a {
        &:hover {
          filter: brightness(1.2);
        }
      }
    }
    .image-wrapper {
      margin-left: 8rem;
    }
  }
  .header {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .logo{
      width: auto;
      height: 40rem;
    }
    .sub-heading,
    .heading {
      color: white;
      font-family: var(--font-body);
      font-size: 2.4rem;
      letter-spacing: .24rem;
      text-align: center;
      text-transform: uppercase;
    }
    .heading {
      display: block;
      font-size: 6rem;
      font-weight: bold;
      margin-top: 1rem;
      @media screen and (max-width: 768px) {
        font-size: 4rem;
      }
    }
    .links-wrapper {
      display: flex;
      margin-top: 3rem;
      a {
        background: var(--primary);
        color: white;
        cursor: pointer;
        display: inline-block;
        font-size: 1.4rem;
        font-weight: bold;
        margin: 0 .5rem;
        padding: 1rem 1.4rem;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        &:hover {
          filter: brightness(1.2);
        }
      }
    }
  }
  .about {
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    padding-top:0 !important;
    @media screen and (max-width: 768px) {
      align-items: center;
      flex-direction: column;
      padding: 3rem !important;
      .image-wrapper {
        margin-bottom: 2rem;
        margin-left: initial;
      }
    }
  }
  .neonBorder {
    border:0.2rem solid rgb(255,255,255);
    border-radius: 2rem;
    padding: 2rem;
    box-shadow:
      0 0 0.2rem rgb(255,255,255),
      0 0 0.2rem rgb(255,255,255),
      0 0 0.2rem rgb(255,255,255),
      0 0 0.8rem #00a1f6,
      0 0 2.8rem #00a1f6,
      inset 0 0 1.2rem #00a1f6;
  }
`;

export default PageWrapper;